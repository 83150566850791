<template>
  <b-form-row>
    <b-col md="12">
      <b-form-row>
        <reg-form-input v-model.trim.lazy="model.firstName" class="col-md-5" :$v="$v.model.firstName" field-name="First Name" />
        <reg-form-input v-model.trim.lazy="model.lastName" class="col-md-5" :$v="$v.model.lastName" field-name="Last Name" />
      </b-form-row>
      <b-form-row>
        <reg-form-input
          v-model.trim.lazy="model.email"
          class="col-md-5"
          type="email"
          :messages="{loqate: loqateMessage, email: 'Parent 1 Email is invalid', duplicateEmailCheck: 'Email is already registered'}"
          :$v="$v.model.email"
          field-name="Parent 1 Email"
        />
        <reg-form-input
          v-model.trim.lazy="emailConfirm"
          class="col-md-5"
          type="email"
          :messages="{sameAsEmail: 'Email Address Mismatch'}"
          :$v="$v.emailConfirm"
          field-name="Confirm Email"
        />
        <div class="col-md-2" />
      </b-form-row>
      <b-form-row>
        <reg-form-input
          v-model.trim.lazy="model.mobilePhone"
          class="col-md-4"
          type="tel" :messages="{numeric: 'Please enter only numbers'}"
          :$v="$v.model.mobilePhone"
          field-name="Telephone"
        />
      </b-form-row>
      <b-form-row>
        <reg-form-input size="sm" class="col-md-2" :id="safeId('pc')" :messages="{ukPostcodeValid: 'Postcode is formatted incorrectly'}" v-model.trim.lazy="model.postcode" :$v="$v.postcode" field-name="Postcode" />
        <reg-form-input size="sm" class="col-md-4" :id="safeId('a1')" v-model.trim.lazy="model.address1" :$v="$v.address1" field-name="Street Address" />
        <reg-form-input size="sm" class="col-md-3" :id="safeId('a2')" v-model.trim.lazy="model.address2" :$v="$v.address2" field-name="Address 2" />
        <reg-form-input size="sm" class="col-md-3" :id="safeId('c')" v-model.trim.lazy="model.city" :$v="$v.city" field-name="City" />
      </b-form-row>
    </b-col>
     <!-- static here is to mount the html within this component -->
    <b-modal static size="lg" no-close-on-backdrop no-close-on-esc :visible="duplicateEmail" @ok="$refs.forgotPw.show()" title="Email already registered" ok-title="Retrieve Password">
      <p>The email address you've just entered already exists in the system.</p>
      <p>To retrieve the login details for this email address, click Retrieve Password.</p>
      <IrisRecoverPassword ref="forgotPw" :email="model.email" hide-link />
    </b-modal>
  </b-form-row>
</template>

<script lang="ts">
import Vue from 'vue'
import type { CustomRule, ValidationRule } from 'vuelidate/lib/validators'
import { PropValidator } from 'vue/types/options'
import { required, maxLength, email, sameAs, helpers, numeric } from 'vuelidate/lib/validators/index.js'
import { regex } from 'vuelidate/lib/validators/common.js'
import memoize from 'lodash/memoize.js'
import axios from 'axios'
import oldAddressAutocomplete from '@iris/components/mixins/oldAddressAutocomplete'

import { Validation } from 'vuelidate'
import LoqateApi, { LoqateEmailValidationResponse } from '~~/iris/src/loqateApi'

type CustomAsyncRule = (value: any, parentVm?: any) => Promise<ReturnType<CustomRule>>
type ValidationRuleFn = (...args: any[]) => ValidationRule
type PartRuleTreeRule = CustomAsyncRule | CustomRule | ValidationRule | ValidationRuleFn
type PartRuleTree = Record<string, PartRuleTreeRule | Record<string, PartRuleTreeRule | Record<string, PartRuleTreeRule | Record<string, PartRuleTreeRule>>>>
interface DataTree extends Vue {
  loqateMessages: Record<string, string>;
  $loqate: LoqateApi
}

// taken from https://en.wikipedia.org/wiki/Postcodes_in_the_United_Kingdom
const UK_POSTCODE_REGEX = /^([A-Za-z][A-Ha-hK-Yk-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$/

export const ukPostcodeValid = regex('postcode', UK_POSTCODE_REGEX)

export const loqateValidator = (responseCallback: (response: LoqateEmailValidationResponse) => void) => {
  return async function (this: DataTree, value: string) {
    if (!helpers.req(value) || !(email as unknown as CustomRule)(value)) return true
    try {
      const response = await this.$loqate.validate(value)
      if (responseCallback) responseCallback.call(this, response)
      return (
        !response.IsDisposableOrTemporary &&
        !response.IsComplainerOrFraudRisk &&
        (response.ResponseCode.indexOf('Valid') === 0 || response.ResponseCode.indexOf('Timeout') === 0)
      )
    } catch (exception) {
      // eslint-disable-next-line no-console
      console.log(exception)
      return true
    }
  }
}


type NullableValueType = {
  familyInformation: NullablePartial<Omit<MathsForFreeRegistrationModel, 'sendLoginDetailsToMobilePhone'>>;
  children: NullablePartial<MinimalMathsForFreeChildData>[];
};

type ValueType = CreateFamilyAccountData['familyInformation'];

// this has to be out here because the memoized function reference is lost in the binding of `this`
const checkDuplicateEmail = memoize(function (this: Vue & {baseUrl: string }, email: string): Promise<boolean> {
  return axios
    .request({
      url: `${this.baseUrl}validate-email?pc=ESTIALABSNM`,
      method: 'POST',
      data: {
        email: email,
        instituteCode: 'ESTIALABS',
        bypassTestEmails: false
      }
    })
    .then(({ data }) => {
      return data.isValidate
    }).catch((err) => {
      // remove the entry from the cache
      checkDuplicateEmail.cache.delete(email)
      throw err
    })
})

export default Vue.extend({
  mixins: [oldAddressAutocomplete],
  props: {
    value: {
      type: Object,
      default: null
    } as PropValidator<ValueType | null>,
    baseUrl: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      lookingUpPostcode: false,
      tandc: true as boolean | null,
      loqateMessage: '',
      // the data
      model: {
        firstName: null,
        lastName: null,
        email: null,
        mobilePhone: null,
        postcode: null,
        address1: null,
        address2: null,
        city: null
      },
      emailConfirm: null as string | null
    }
  },
  computed: {
    duplicateEmail (): boolean {
      if (this.$v.model.email) {
        const emailValidation: Validation & ValidationEvaluation = this.$v.model.email
        return !emailValidation.$pending && !emailValidation.duplicateEmailCheck
      }
      return false
    },
    valid (): boolean {
      return !this.$v.$invalid && !this.$v.$pending
    },
    address1: {
      get() { return this.model.address1 },
      set(value) {
          this.model.address1 = value
      },
    },
    address2: {
      get() { return this.model.address2 },
      set(value) {
          this.model.address2 = value
      },
    },
    postcode: {
      get() { return this.model.postcode },
      set(value) {
          this.model.postcode = value
      },
    },
    city: {
      get() { return this.model.city },
      set(value) {
          this.model.city = value
      },
    }
  },
  watch: {
    model: {
      handler (model: NullableValueType) {
        this.$emit('input', Object.assign(this.value || {}, model))
      },
      deep: true
    },
    valid(v) {
      this.$emit('valid', v)
    },
    value: {
      handler (value: ValueType) {
        if (value) {
          Object.assign(this.model, value)
          this.emailConfirm = value.email
        }
      },
      immediate: true
    }
  },
  methods: {
    checkDuplicateEmail
  },
  validations () {
    return {
      emailConfirm: {
        required,
        sameAsEmail: sameAs(function (this: {model: NullableValueType}) {
          return this.model.email
        })
      },
      model: {
        firstName: {
          required,
          maxLength: maxLength(148)
        },
        lastName: {
          required,
          maxLength: maxLength(48)
        },
        email: {
          required,
          email,
          maxLength: maxLength(128),
          async duplicateEmailCheck (this: Vue & {checkDuplicateEmail: (email: string) => Promise<boolean>}, value: string) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore the email() type is incorrect and unable to compile
            if (!helpers.req(value) || !email(value)) return true
            try {
              return await this.checkDuplicateEmail(value)
            } catch (e) {
              return false
            }
          },
          loqate: loqateValidator(function (response) {
            this.loqateMessage = response.ResponseMessage
          }),
        },
        mobilePhone: {
          required,
          numeric,
          maxLength: maxLength(64)
        },
      },
      address1: {
        required,
        maxLength: maxLength(148)
      },
      address2: {
        maxLength: maxLength(148)
      },
      city: {
        required,
        maxLength: maxLength(32)
      },
      postcode: {
        required,
        ukPostcodeValid,
        maxLength: maxLength(16)
      }
    }
  }
})
</script>
